import React, { Component, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  Alert,
  Row,
  Col
} from 'react-bootstrap';
import swal from 'sweetalert';

import Header from '../layout/Header';

export default function Contact(props) {
  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
  const history = useHistory();

  const product = props.location.state;

  console.log(product);

  return (
    <div className="site-main">
      <Header />
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
              </div>
            </div>{/* /.col-md-12 */}
          </div>{/* /.row */}
        </div>{/* /.container */}
      </div>
      {/* page-title end*/}
      <section className="ttm-row zero-padding-section clearfix">
        <div className="container-xl">
          <div className="row no-gutters">{/* row */}
            <div className="col-lg-5">
              <div className="spacing-9">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>Come Visit Us At</h5>
                    <h2 className="title">Our Address</h2>
                  </div>
                </div>{/* section title end */}
                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">{/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti-location-pin" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">{/* featured title */}
                          <h5>Office Address</h5>
                        </div>
                        <div className="featured-desc">{/* featured desc */}
                          <p>Chennai, India 600092</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">{/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti ti-email" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">{/* featured title */}
                          <h5>Our Email</h5>
                        </div>
                        <div className="featured-desc">{/* featured desc */}
                          <p>Info@cognitude.co.in</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>Send Message</h5>
                    {product?.know_product ?
                      <h2 className="title">{product?.know_product} Drop Us A Line</h2>
                      : <h2 className="title">Drop Us A Line</h2>}

                  </div>
                </div>{/* section title end */}
                <Formik
                  initialValues={{ name: '', company: '', email: '', designation: '', phone: '', country: '', requirement: '' }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    email: Yup.string().email('This doesn’t look like a valid email').required(),
                    requirement: Yup.string().required(),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    const formData = new FormData()
                    formData.append("name", values.name)
                    formData.append("email", values.email)
                    formData.append("phone", values.phone)
                    formData.append("company", values.company)
                    formData.append("designation", values.designation)
                    formData.append("country", values.country)
                    formData.append("requirement", values.requirement)

                    const endpoint = `${REACT_APP_CGN_API_URL}api/v1/contact/store`;
                    const headers = {
                      "Access-Control-Allow-Origin": true,
                      "content-type": "application/json",
                      "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
                    };

                    axios({
                      url: endpoint,
                      method: 'post',
                      data: formData,
                      headers: headers
                    }).then((response) => {
                      if (response.data.success) {
                        swal("Thanks you!", response.data.message, "success").then(function () {
                          history.push("/");
                        });
                        setSubmitting(false);
                        resetForm();
                      }
                    }).catch((error) => {
                      if (error.response) {
                      } else if (error.request) {
                        console.log(error.request);
                      } else {
                        console.log('Error', error.message);
                      }
                    });
                  }}
                >
                  {({
                    formik,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                    setValues,
                    isSubmitting
                  }) => (
                    <>
                      <Form className="ttm-quote-form" onSubmit={handleSubmit}>
                        <Form.Group as={Row}>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="name"
                                className={(touched.name && errors.name) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Your Name (*)"
                              />
                              <ErrorMessage name="name" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="company"
                                className={(touched.company && errors.company) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Company"
                              />
                              <ErrorMessage name="company" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="email"
                                className={(touched.email && errors.email) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Email (*)"
                              />
                              <ErrorMessage name="email" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="designation"
                                className={(touched.designation && errors.designation) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Designation"
                              />
                              <ErrorMessage name="designation" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="phone"
                                className={(touched.phone && errors.phone) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Phone"
                              />
                              <ErrorMessage name="phone" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="location"
                                className={(touched.location && errors.location) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Location"
                              />
                              <ErrorMessage name="location" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col md={12}>
                            <div className="form-group">
                              <Field
                                type="text"
                                name="requirement"
                                component="textarea"
                                rows="4"
                                className={(touched.requirement && errors.requirement) ? 'form-control is-invalid' : 'form-control bg-white'}
                                placeholder="Brief your Requirement (*)"
                              />
                              <ErrorMessage name="requirement" className="invalid-feedback" component="div" />
                            </div>
                          </Col>
                        </Form.Group>
                        <button type="submit" className="ttm-btn ttm-btn-size-sm rounded ttm-bgcolor-skincolor mr-4" disabled={isSubmitting}>
                          {isSubmitting ? "Please wait..." : "Submit"}
                        </button>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
    </div>
  );
}

