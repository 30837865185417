import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";

import axios from "axios";
import Moment from "react-moment";

import Header from '../layout/Header';

export default function CareerDetails(props) {
  let { jobID } = useParams();
  let history = useHistory();
  const [jobView, setJobView] = useState({});
  
  useEffect(() => {
    const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
    async function getJobDetails() {
      const endpoint = `${REACT_APP_CGN_API_URL}api/v1/job/${jobID}`;
      const headers = {
        "content-type": "application/json",
        "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
      };

      await axios({
        url: endpoint,
        method: 'get',
        headers: headers
      }).then((response) => {
        setJobView(response.data.data);
      }).catch((error) => {
        if (error.response) {
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });      
    }
    getJobDetails();
  }, []);

  return (
    <div className="site-main">
        <Header />
        {jobView && 
          <section className="ttm-row career-detail-section clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <Link to={`/apply/${jobID}`} className="ttm-btn ttm-btn-size-sm rounded ttm-icon-btn-right ttm-bgcolor-skincolor mr-4">Apply Now</Link>  
                  <button className="ttm-btn ttm-btn-size-sm rounded ttm-icon-btn-right" onClick={() => history.goBack()}>Back</button>
                </div>
                <div className="col-md-8">
                    <div className="job-title">
                      <h2>{ jobView.attributes?.title }</h2>
                    </div>
                    <div className="job-content" dangerouslySetInnerHTML={ {__html: jobView.attributes?.content} } />
                </div>
                <div className="col-md-4">
                  <div className="job-info">
                    <div className="info-item">
                      <span className="info-head">Ref:</span>
                      <span>{ jobView?.id }</span>
                    </div>
                    <div className="info-item">
                      <span className="info-head">Posted on:</span>
                      <span>
                        <Moment format="MMM D, YYYY">
                        { jobView.attributes?.created_at }
                          </Moment>
                      </span>
                    </div>
                    <div className="info-item">
                      <span className="info-head">Experience level:</span>
                      <span>{ jobView.attributes?.experience_level }</span>
                    </div>
                    <div className="info-item">
                      <span className="info-head">Contract type:</span>
                      <span>{ jobView.attributes?.contract_type }</span>
                    </div>
                    <div className="info-item">
                      <span className="info-head">Location:</span>
                      <span>{ jobView.attributes?.location }</span>
                    </div>
                    <div className="info-item">
                      <span className="info-head">Department:</span>
                      <span>{ jobView.attributes?.department }</span>
                    </div>
                  </div>
                </div>
              </div>            
          </div>
        </section>
      }
    </div>
  );
}