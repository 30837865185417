import React, { useEffect, useState } from 'react';

import Header from '../layout/Header';

export default function LifeAtCognitude(props) {
  return (
    <div className="site-main">
        <Header />
        <div className="ttm-page-title-row">
            <div className="container-xl">
            <div className="row">
                <div className="col-md-12"> 
                <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title">Life At Cognitude</h1>
                    </div>
                </div>
                </div>
            </div>
            </div>                     
        </div>    

        <section className="ttm-row career-section clearfix pb-4">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <p>Do you know what makes us different from others? At Cognitude, we know how to strike the right balance between work and life. We believe the key to success is enjoying life as much as we enjoy our work. Our team members are exposed to great learning experience. Change is a constant throughout your career at Cognitude.You can seek even greater depth within your expertise, change scope in your current position, or even explore a new opportunity here. Whatever you decide, you’ll play the lead role in your growth, and we’ll be behind you all the way. For everything we create, we consider its impact — on our customers, our colleagues, and our planet. The same innovation that goes into creating applications for our clients.</p>
                <p>We believe in a safe, respectful and supportive workplace for everyone.</p>
                <p>Ethical work culture. We believe in open, intellectually honest culture where everyone contribute and do their life's best work.</p>
              </div>
            </div>
        </div>
      </section>

      <section className="ttm-row grid-section clearfix pt-0">
          <div className="container-xl">
            <h2 className="mb-5">Employee stories</h2>
            <div className="row">                
                <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src="images/teams/ram.jpg" alt="ram" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                        <h5>Ram</h5>
                    </div>
                    <p className="category">Web Designer</p>
                  </div>
                </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src="images/teams/syed.jpeg" alt="syed" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                          <h5>Syed</h5>
                      </div>
                      <p className="category">Web Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src="images/teams/viggie.jpg" alt="viggie" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                          <h5>Viggie</h5>
                      </div>
                      <p className="category">Web Designer</p>
                    </div>
                  </div>
                </div>                        
              </div>
              <div className="row">
                <p>1. Our work usually starts with analysing the need, plan strategies &amp; tools that strike a balance between cost-effectiveness and scale.</p>
                <p>2. We often alternate between user &amp; developer shoes during the development process.  This makes sure a solution that clicks.</p>
                <p>3. A platform that caters from a novice individual to experienced corporate person.  It looks simple, not intimidating, yet can provide ERP level customization for corporate needs as well.</p>
            </div>
          </div>
        </section>
    </div>
  );
}