import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu"><Link to="/">Home</Link></li>
                    {/* <li className="menu"><Link to="/">Cognitude Academy</Link></li> */}
                    <li className="menu-hasdropdown"><a>Careers</a>
                        <ul className="menu-dropdown">
                            <li><Link to="/careers">Open Positions</Link></li>
                            <li><Link to="/life-at-cognitude">Life at Cognitude</Link></li>
                        </ul>
                    </li>
                    <li className="menu"><Link to="/contact">Contact Us</Link></li>
                </ul>
            </nav>
    
            )
    }
}