import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

export class Home3_mainpage extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (       
          <div className="site-main">            
            {/* service-section */}
            <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
                <div className="container-xl">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                        <h5>We Make Connections</h5>
                        </div>
                        <h2 className="title">We provide best <span>Services</span></h2>
                    </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-6 col-lg-4">
                    <div className="featured-icon-box style6">
                        <div className="featured-content">
                          <div className="featured-title">
                              <h5>IT Services</h5>
                          </div>
                          <div className="featured-desc">
                              <ul className="ttm-list ttm-list-style-icon">
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Ecommerce Application Development</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Enterprise Application Development</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Website Development Maintenance Services</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Mobile Application Development</span></li>
                              </ul>
                              <p>We have proven expertise in creating robust and highly secured enterprise-grade applications.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                    <div className="featured-icon-box style6">
                        <div className="featured-content">
                          <div className="featured-title">
                              <h5>IT Consultancy</h5>
                          </div>
                          <div className="featured-desc">
                              <ul className="ttm-list ttm-list-style-icon">
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Industry - Specific Business Solutions</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Service - Specific Consulting</span></li>
                              </ul>
                              <p>We provide IT management services tailer made to organization’s needs. Our business solutions are equipped to overcome various industry-specific challenges.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                    <div className="featured-icon-box style6">
                        <div className="featured-content">
                          <div className="featured-title">
                              <h5>Human Resource Management</h5>
                          </div>
                          <div className="featured-desc">
                              <ul className="ttm-list ttm-list-style-icon">
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Talent Transformation</span></li>
                                <li><i className="far fa-circle ttm-textcolor-skincolor" /> <span className="ttm-list-li-content">Resourcing</span></li>
                              </ul>
                              <p>Reskilling and upskilling have become part of every corporate learning and employee development program. We have accomplished supplying resources to many of our clients across the globe and also leveraging our expertise in technology to source the right skills for the right requirements.</p>
                          </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* service-section end*/}
            {/* progress-bar-section */}
            <section className="ttm-row zero-padding-section ttm-bgcolor-grey clearfix">
                <div className="container-xl">
                <div className="row no-gutters">
                    <div className="col-md-12">
                    <div className="ttm-bgcolor-white ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                        </div>
                        <div className="layer-content">
                        <div className="row">
                            <div className="col-lg-6">
                            <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src="images/3-Years-of-IT.jpg" title="single-img-five" alt="single-img-five" />
                            </div>
                            <div className="about-overlay-02">
                                <h3>3 Years of</h3>
                                <p>IT Development and Consulting</p>
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="res-991-mt-30">
                                <div className="section-title with-desc clearfix">
                                <div className="title-header">
                                    <h5>About Cognitude</h5>
                                </div>
                                <div className="title-desc">
                                  <p>Cognitude is a global consulting company formed by consultants having more than 2 decades of experience in providing IT Solution and Human Resource Management. 
                                  We are partnering with clients worldwide to provide innovative technological solutions, sourcing right talents to right skills requirements, nurture relevant skills on the cutting edge technologies and introduce process optimization support for our exciting clients.
                                  </p>
                                  <p>Cognitude offers industry expertise in Tele communication, Retail, Banking &amp; Financial services.  We Help Every Business Go Online!</p>
                                  <p>By offering standardised and custom digital services, we make it easy for business owners to take their business operations online without any hassle, with complete transparency and cost efficiency.</p>
                                  <p>Our long-term objective is to apply new technologies such as Artificial Intelligence, Machine Learning &amp; Robotic Process Automation to have high benefits for our users.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            {/* progress-bar-section end */}

            {/* about-us-section */}
            <section className="ttm-row zero-padding-section ttm-bgcolor-grey clearfix">
              <div className="container-xl">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <div className="ttm-bgcolor-white ttm-bg ttm-col-bgcolor-yes ttm-right-span spacing-8 position-relative z-1">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                      <div className="layer-content">
                        <div className="row">
                          <div className="col-lg-6">
                            <div>
                              {/* section title */}
                              <div className="section-title with-desc clearfix">
                                <div className="title-header">
                                  <h5>Why Cognitude</h5>
                                </div>
                                <div className="title-desc">
                                  <p>Cognitude is working with international clients to bring their business online. Whether you want to build a custom app for ecommerce, food delivery, tech or lifestyle, we provide complete support from beginning till the end. Our team works relentlessly to deliver the project as per your requirement. Our core values and principles continue to align us to the track of excellence we wanted to pursue when we started out, while also enabling us to rapidly serve the needs of diverse industries and requirements.</p>
                                </div>
                              </div>{/* section title end */}
                              {/* row */}
                              <div className="row no-gutters mt-35">
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                  <ul className="ttm-list ttm-list-style-icon">
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ecommerce Application Development</span></li>
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Enterprise Application Development</span></li>
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Website Development Maintenance Services</span></li>
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Mobile Application Development</span></li>
                                  </ul>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-6">
                                  <ul className="ttm-list ttm-list-style-icon">
                                  <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industry - Specific Business Solutions</span></li>                                    
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Service - Specific Consulting</span></li>
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Talent Transformation</span></li>
                                    <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Resourcing</span></li>
                                  </ul>
                                </div>
                              </div>
                              {/* row END */}
                            </div>
                          </div>
                          <div className="col-lg-6 res-991-text-center">
                            <div className="res-991-mt-30">
                              {/* ttm_single_image-wrapper */}
                              <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src="images/Why-Cognitude.jpg" title="Why-Cognitude" alt="Why-Cognitude" />
                              </div>{/* ttm_single_image-wrapper end */}
                              <div className="about-overlay-03">
                                <h3>3 Years Experience</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* about-us-section end*/}
            {/* first-row-title-section */}
            {/* <section className="ttm-row third-row-title-section ttm-bg ttm-bgimage-yes bg-img12 res-991-mt-0 clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl" style={{minHeight: '300px'}}></div>
            </section> */}
            <div className="">
              <img className="img-fluid" src="../images/bg-image/Cognitude map.png" alt="map-img" />
            </div>
            {/* first-row-title-section end*/}
          </div>
        )
    }
}


export default Home3_mainpage;