import React, { useEffect, useState } from 'react';

import axios from "axios";
import Pagination from "react-js-pagination";
import ReactLoading from "react-loading";

import Header from '../layout/Header';
import NewsItem from './NewsItem';

export default function News(props) {
  const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
  const [newsList, setNewsList] = useState([]);
  const [pageDetails, setPageDetails] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [dataReceived, setDataReceived] = useState(undefined);
  
  useEffect(() => {
    async function fetchNews() {
      const endpoint = `${REACT_APP_CGN_API_URL}api/v1/news?page=${pageNumber}`;
      const headers = {
        "content-type": "application/json",
        "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
      };

      await axios({
        url: endpoint,
        method: 'get',
        headers: headers
      }).then((response) => {
        console.log(response.data.data);
        setNewsList(response.data.data);
        setPageDetails(response.data.meta);
        setDataReceived(true);
      }).catch((error) => {
        if (error.response) {
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
    }
    fetchNews();
  }, [pageNumber]);

  const nextPage = (event) => {
    const selectedPage = event;
    setPageNumber(selectedPage);
  };

  return (
    <div className="site-main">
      <Header />
      <div className="ttm-page-title-row">
          <div className="container-xl">
              <div className="row">
                  <div className="col-md-12"> 
                  <div className="title-box text-center">
                      <div className="page-title-heading">
                      <h1 className="title">News</h1>
                      </div>
                      <div className="breadcrumb-wrapper">
                      <span>
                          <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span>News</span>
                      </div>  
                  </div>
                  </div>
              </div>
          </div>                     
      </div>
      <div className="sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
              <div className="row">
                  <div className="col-lg-9 content-area">
                  {!dataReceived ? (
                  <div className="component-loader">
                    <ReactLoading
                      type={"bubbles"}
                      color={"#263045"}
                      height={100}
                      width={100}
                    />
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="career-lists">
                    {newsList
                      ?.map((newsItem, i) => (
                        <NewsItem newsItem={newsItem} newsID={newsItem.id} key={i} />
                      ))}                  
                    </div>
                    {newsList.length == 0 && dataReceived && 
                      <div className="text-center"><h3>No News Found!</h3></div>
                    }
                    {pageDetails && pageDetails.total > 1 && 
                      <div className="career-pages ttm-pagination">
                        <Pagination 
                          activePage={pageDetails.current_page}
                          totalItemsCount={pageDetails.total}
                          itemsCountPerPage={pageDetails.per_page}
                          onChange={nextPage}
                          itemClass="page-item"
                          linkClass="page-numbers"
                          activeLinkClass="current"
                          firstPageText="First"
                          lastPageText="Last"
                        />
                      </div>
                    }
                  </React.Fragment>
                )}
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}