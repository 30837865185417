import React from 'react';
import { Link } from "react-router-dom";

export default function CareerItem({ jobItem }) {
  const { title, experience_level, location } = jobItem.attributes;

  return (
    <div className="list-box">
      <div className="main-location"><h5>India</h5></div>
      <Link to={"/job/" + jobItem.id} className="job-detail">
        <h2 className="job-title">
          { title } 
          { experience_level && ' | '+ experience_level }
          { location && ' | '+ location }
        </h2>
      </Link>
      <Link to={"/job/" + jobItem.id} className="job-detail">More</Link>
    </div>
  );
}