import React, { useEffect, useState } from 'react';

import Header from '../layout/Header';

export default function EmployeeStories(props) {
  return (
    <div className="site-main">
        <Header />
        <div className="ttm-page-title-row">
            <div className="container-xl">
            <div className="row">
                <div className="col-md-12"> 
                <div className="title-box text-center">
                    <div className="page-title-heading">
                    <h1 className="title">Careers</h1>
                    </div>
                    <div className="breadcrumb-wrapper">
                    <span>
                        <a title="Homepage" href={process.env.PUBLIC_URL + '/'} ><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Employee Stories</span>
                    </div>  
                </div>
                </div>
            </div>
            </div>                     
        </div>    

        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="row">
                <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src="images/teams/ram.jpg" alt="ram" />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                        <h5>Ram</h5>
                    </div>
                    <p className="category">Web Designer</p>
                  </div>
                </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src="images/teams/syed.jpeg" alt="syed" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                          <h5>Syed</h5>
                      </div>
                      <p className="category">Web Designer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">
                      <img className="img-fluid" src="images/teams/viggie.jpg" alt="viggie" />
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                          <h5>Viggie</h5>
                      </div>
                      <p className="category">Web Designer</p>
                    </div>
                  </div>
                </div>                        
              </div>
              <div className="row">
                <p>1. Our work usually starts with analysing the need, plan strategies &amp; tools that strike a balance between cost-effectiveness and scale.</p>
                <p>2. We often alternate between user &amp; developer shoes during the development process.  This makes sure a solution that clicks.</p>
                <p>3. A platform that caters from a novice individual to experienced corporate person.  It looks simple, not intimidating, yet can provide ERP level customization for corporate needs as well.</p>
            </div>
          </div>
        </section>
    </div>
  );
}