import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
  "mdbreact";
import { Link } from 'react-router-dom';

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel
            activeItem={1}
            length={6}
            showControls={false}
            showIndicators={true}
            id="Home_banner"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="First slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner1">
                      <MDBAnimation className="slideInUp" count={1} >
                        <a href="https://zointvc.com" className="ttm-btn ttm-btn1 ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" target="_blank">Start now</a>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <h2 className="ttm-textcolor-darkgrey flipInY">Zoint Video conferencing </h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">Host a video meeting in one click! No downloads required. No sign ups.</p>
                      </MDBAnimation>

                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} style={{ zIndex: 1000, position: 'relative' }}>
                        <Link to={{ pathname: '/contact', state: { know_product: 'To know more about Voucher Management System – (R2MS)' } }} className="ttm-btn ttm-btn2 ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white">Know more</Link>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <h2 className="ttm-textcolor-darkgrey">Voucher Management System (VMS)</h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">Recharge Telephone cards, Vouchers and do travel reservations all in one.</p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="3">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner3">
                      <MDBAnimation className="slideInUp" count={1} style={{ zIndex: 1000, position: 'relative' }}>
                        <Link to={{ pathname: '/contact', state: { know_product: 'To know more about Point of sale management system (PMS)' } }} className="ttm-btn ttm-btn3 ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white">Know more</Link>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <h2 className="ttm-textcolor-darkgrey">Point of sale management system (PMS)</h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">Manage  Point of Sale / casher applications.</p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="4">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner4">
                      <MDBAnimation className="slideInUp" count={1} style={{ zIndex: 1000, position: 'relative' }}>
                        <h2 className="ttm-textcolor-darkgrey">Training platform</h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">One stop solution for trainings. A platform for Trainers, Tutors, Students and for Corporates.</p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p>Under development</p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="5">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner5">
                      <MDBAnimation className="slideInUp" count={1} >
                        <h2 className="ttm-textcolor-darkgrey">Digital Journey with Cognitude</h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">Among web development companies in India, Cognitude is the Best Professional Web Development Company. We have strong web designing and development experience, strong domain knowledge and team of highly skilled resources.</p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="6">
                <MDBView>
                  <img className="d-block w-100" src="../../images/slides/slider-mainbg0001.jpg" alt="Second slide" />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="cog-banner">
                    <div className="carousel-caption-inner banner6">
                      <MDBAnimation className="slideInUp" count={1} >
                        <h2 className="ttm-textcolor-darkgrey">Applying engineering principles to develop software</h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} >
                        <p className="text">Cognitude's approach to create a software is more than software development. We create simple, effective and future ready digital solutions.</p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>

            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;