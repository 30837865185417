import React, { Component } from 'react'
// import { Link } from 'react-router-dom';

import { Menu } from './menu';
import Mobilemenu from './Mobile_menu';

export class Header extends Component {
    state = {
        show: false,
      }    
    toggle = () => this.setState((currentState) => ({show: !currentState.show}));
    render() {        
        return (
                <header id="masthead" className="header ttm-header-style-01">
                {/* ttm-header-wrap */}
                <div className="ttm-header-wrap">
                <div id="navbar" className="ttm-stickable-header clearfix">
                    <div className="site-header-menu">
                        <div className="container-xl">
                            {/* site-branding */}
                            <div className="site-branding">
                            {/* <Link to="/">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/logo.png" />
                            </Link> */}
                             <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Altech" rel="home">
                                <img id="logo-img" className="img-fluid" alt="logo" src="images/logo.png" />
                            </a>
                            </div>
                            {/* site-branding end*/}
                            {/*site-navigation */}
                            <div className="site-navigation">
                                <div className="ttm-right-header">
                                   
                                </div>
                                <Menu />
                                <div className="mobilemenu"><Mobilemenu/></div>
                            </div>
                            {/*site-navigation end*/}
                        </div>
                    </div>
                </div>
                </div>
                {/* ttm-header-wrap end*/}
            </header>               
        )
    }
}

export default Header;