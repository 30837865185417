import React, { useEffect, useState } from 'react';

import axios from "axios";
import Moment from "react-moment";
import { useHistory, useParams, Link } from "react-router-dom";

import Header from '../layout/Header';

export default function NewsDetails(props) {
    const { REACT_APP_CGN_API_URL, REACT_APP_BEARER_TOKEN } = process.env;
    let { newsID } = useParams();
    let history = useHistory();
    const [newsView, setNewsView] = useState({});
    
    useEffect(() => {        
        async function getNewsDetails() {
            const endpoint = `${REACT_APP_CGN_API_URL}api/v1/news/${newsID}`;
            const headers = {
                "content-type": "application/json",
                "Authorization": `Bearer ${REACT_APP_BEARER_TOKEN}`
            };

            await axios({
                url: endpoint,
                method: 'get',
                headers: headers
            }).then((response) => {
                setNewsView(response.data.data);
            }).catch((error) => {
                if (error.response) {
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
            });      
        }
        getNewsDetails();
    }, []);

    return (
        <div className="site-main">
            <Header />
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Blog View</h1>
                                </div>
                                <div className="breadcrumb-wrapper">
                                    <span>
                                        <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                    </span>
                                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                    <span>Blog View</span>
                                </div>  
                            </div>
                        </div>
                    </div> 
                </div>                    
            </div>
            {newsView &&
                <div className="sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area">
                                <article className="post ttm-blog-single clearfix">
                                    <div className="ttm-post-featured-wrapper">
                                        <div className="ttm-post-featured">
                                            <img className="img-fluid" src={ REACT_APP_CGN_API_URL + 'storage/app/' + newsView.attributes?.image } alt="post-01" />
                                        </div>
                                    </div>
                                    <div className="ttm-blog-single-content">
                                        <div className="entry-content">
                                            <div className="ttm-post-entry-header">
                                                <div className="post-meta">
                                                    <span className="ttm-meta-line entry-date"><i className="fa fa-calendar" /><time className="entry-date published" dateTime="2018-07-28T00:39:29+00:00">July 28, 2018</time></span>
                                                </div>
                                            </div>
                                            <div className="separator">
                                                <div className="sep-line mt-3 mb-4" />
                                            </div>
                                            <h2>{ newsView.attributes?.title }</h2>
                                            <div dangerouslySetInnerHTML={ {__html: newsView.attributes?.content } }></div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>            
    )
}