import React from 'react';
import { Link } from "react-router-dom";
import Moment from "react-moment";

export default function NewsItem({ newsItem, newsID }) {
  const { REACT_APP_CGN_API_URL } = process.env;
  const { id, image, title, short_content, created_at } = newsItem.attributes;

  return (
    <article className="post ttm-blog-classic clearfix">
      <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
          <div className="ttm-post-featured">
          <img className="img-fluid" src={ REACT_APP_CGN_API_URL + 'storage/app/' + image } alt={title} />
          </div>
      </div>
      <div className="ttm-blog-classic-content">
          <div className="ttm-post-entry-header">
          <div className="post-meta">
              <span className="ttm-meta-line entry-date"><i className="fa fa-calendar" />
                <time className="entry-date published" dateTime="2018-07-28T00:39:29+00:00">
                  <Moment format="MMM D, YYYY">
                    { created_at }
                  </Moment>
                </time>
              </span>
          </div>
          </div>
          <div className="entry-content">
          <header className="entry-header">
              <h2 className="entry-title"><a href={process.env.PUBLIC_URL + '/Single_blog'}>{ title }</a></h2>
          </header>
          <div className="ttm-box-desc-text">
              <div dangerouslySetInnerHTML={ {__html: short_content } }></div>
          </div>
          <div className="separator">
              <div className="sep-line mt-25 mb-25" />
          </div>
          <div className="ttm-blogbox-desc-footer">
              <div className="ttm-blogbox-footer-readmore d-inline-block">
                  <Link to={"/news/" + newsID}><a className="ttm-btn ttm-btn-size-sm ttm-textcolor-skincolor btn-inline ttm-icon-btn-right">Read More <i className="ti ti-angle-double-right" /></a></Link>
              </div>
          </div>
          </div>
      </div>
    </article>
  );
}